// map containing the error values returned in english from the API / Keycloak
const errorTranslations = {
  "Invalid Token: Token decoding failed: The token has expired":
    "トークンの期限が切れました。",
  "app not found": "アプリ情報は正しくありません。",
  "User exists with same email":
    "同じメールアドレスのユーザーが既に存在します。",
  "User not found": "ユーザがKeycloakに存在していません。",
  "user with the same phone number exists in database":
    "同じ電話番号のユーザーが既に存在します",
  "User exists with same username":
    "同じログインIDのユーザーが既に存在します。",
  "Missing parameter: login_id and login_password are required":
    "ログインIDとパスワードは必須です。",
  "error-invalid-email": ({ field, params }) =>
    `無効な${field}アドレス: ${params[1]}。有効なメールアドレスを入力してください。`,
    "Kana name カナはカタカナのみ使用できます。":
    "氏名カナはカタカナのみ使用可能です。",
    "First kana name かな名はひらがなのみ使用できます。": "名前はひらがなのみ使用可能です。",
    "Last furigana name かな名はひらがなのみ使用できます。": "名前はひらがなのみ使用可能です。",
    "First kana name フリガナは英字とハイフンのみ使用できます。": "名前は英数字、ハイフンのみ使用可能です。",
    "Last kana name フリガナは英字とハイフンのみ使用できます。" : "名前は英数字、ハイフンのみ使用可能です。",
  "Furigana name フリガナは英字とハイフンのみ使用できます。":
    "名前は英数字、日本語文字、ハイフンのみ使用可能です。",
  "Name 名前は英数字、日本語文字、ハイフンのみ使用できます。":
    "名前は英数字、日本語文字、ハイフンのみ使用できます。",
  "Personal tel 電話番号は1234567890の形式で入力してください。":
    "電話番号は1234567890の形式で入力してください。",
  "Company tel 電話番号は1234567890の形式で入力してください。":
    "電話番号は1234567890の形式で入力してください。",
  "Company home page is invalid": "有効なURLを入力してください。",
  "Personal tel can't be blank": "電話番号を入力してください。",
  "Validation failed: Name can't be blank": "名前を入力してください。",
  "Validation failed: Kana name can't be blank":
    "名前（カナ）を入力してください。",
  "Validation failed: Postcode can't be blank": "郵便番号を入力してください。",
  "Validation failed: Address can't be blank": "住所を入力してください。",
  "Validation failed: Url can't be blank": "URLを入力してください。",
  "something happened. When create Yobery BROADCAST-GROUP":
    "同じ名称のグループが既に存在します。",
  "An unexpected error occurred while updating password":
    "パスワードの更新中に予期しないエラーが発生しました。",
  "Couldn't find Role with 'id'=1": "該当するロールが見つかりませんでした。",
  "no available licenses for YOBELY": "YOBELYのライセンスは利用できません。",
  "no available licenses for KAKELY": "KAKELYのライセンスは利用できません。",
  "no available licenses for WorkJoy": "WorkJoyのライセンスは利用できません。",
  "unable to find contract for app YOBELY": "YOBELYは未契約です。",
  "unable to find contract for app KAKELY": "KAKELYは未契約です。",
  "unable to find contract for app WorkJoy": "WorkJoyは未契約です。",
  "license status for YOBELY is no contract": "YOBELYは未契約です。",
  "license status for KAKELY is no contract": "KAKELYのライセンスは未契約です",
  "license status for WorkJoy is no contract": "WorkJoyは未契約です。",
  "cannot register department without position":
    "役職なしでは部署を登録できません",
  "cannot set KAKELY license number lower than current users":
    "ご利用中のKAKELYユーザー数より少ないライセンス数に変更することはできません",
  "cannot set YOBELY license number lower than current users":
    "ご利用中のYOBELYユーザー数より少ないライセンス数に変更することはできません",
  "cannot set WorkJoy license number lower than current users":
    "ご利用中のWorkJoyユーザー数より少ないライセンス数に変更することはできません",
  "HTTP 405 Method Not Allowed": "ユーザがKeycloakに存在していません。",
};

export default errorTranslations;
