import React, { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CsvUploader } from "../../common/UploadCsvTemplate";
import { postUserDataArray } from "../../services/API/Requests";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import LoadingIcon from "../../common/LoadingIcon";
import { USER } from "../../types/user";
import { GetCurrentDate } from "../../services/Utilities";
import { userHeaderMapping } from "../../services/TranslateCsvHeaders";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { USER_TYPE } from "../../constants/Enums";
import { USER_PATHS } from "../../constants/NavigationPaths";

/**
 * UserCSVUploadPage - A component for uploading user data via a CSV file.
 *
 * This component provides a CSV uploader for adding multiple user records to the system at once.
 * It allows users to download a CSV template, select and upload a CSV file, and view a preview of the uploaded data.
 *
 * State:
 * - `loading` (boolean): Indicates whether the component is in a loading state while processing the CSV data or making requests.
 * - `error` (string | null): Stores error messages encountered during the CSV upload process or API interactions.
 * - `csvData` (array): Stores the parsed data from the uploaded CSV file.
 *
 * Functions:
 * - `validateHeaders`: Verifies that the uploaded CSV file contains all the required headers.
 * - `handleCsvUpload`: Validates and uploads the user data from the CSV file to the backend API. Displays success or error messages based on the response.
 * - `onCancel`: Navigates back to the user list page without saving any changes.
 *
 * API Interactions:
 * - `postUserData`: Sends the parsed user data to the backend API for creating user records.
 *
 * Components:
 * - `CsvUploader`: A reusable component for handling CSV file uploads and displaying a preview of the data.
 * - `ToastContainer`: A component for displaying toast notifications.
 *
 * Toast Notifications:
 * - `noCsvImportError`: Displays an error message if no CSV data is available for upload.
 * - `uploadCsvDataToast`: Displays a success message with the number of user records uploaded successfully.
 * - `failCsvDataToast`: Displays an error message if there is an issue uploading the CSV data.
 *
 * Usage:
 * This component is used for bulk uploading user data through a CSV file. It is typically accessible from an admin or management page where user management is performed.
 *
 * Example Usage:
 * <UserCSVUploadPage />
 *
 * Note:
 * - The `validateHeaders` function ensures that the uploaded CSV file has all the required headers before processing the data.
 * - The `handleCsvUpload` function maps the CSV data to the user object structure expected by the API and sends it to the backend.
 * - The component includes error handling to provide feedback if the CSV data is not in the expected format or if there are issues during the upload process.
 * - The user data includes additional information such as roles, tenant information, and assigned applications, which are also handled during the upload.
 */

const UserCSVUploadPage = () => {
  const navigate: NavigateFunction = useNavigate();

  const [csvData, setCsvData] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [failedUsers, setFailedUsers] = useState<[]>();

  const currentDate = GetCurrentDate();

  const sampleData = [
    {
      first_name: "花子",
      last_name: "佐藤",
      first_kana_name: "はなこ",
      last_kana_name: "さとう ",
      first_furigana_name: "SATO",
      last_furigana_name: "HANAKO",
      employee_number: "123456",
      email: "hanako.sato@example.com",
      is_display: true,
      personal_tel: "09023456789",
      login_id: "hanako",
      login_password: "securePassword1!",
      apps: ["YOBELY", "KAKELY"],
    },
  ];

  const expectedHeaders: string[] = [
    "社員番号（任意）",
    "漢字（名）",
    "漢字（姓）",
    "かな（名）",
    "かな（姓）",
    "英字（名）（任意）",
    "英字（姓）（任意）",
    "メールアドレス（任意）",
    "アカウント状態（有効：TRUE、無効：FALSE）",
    "電話番号",
    "ログインID",
    "ログインパスワード",
    "利用アプリ",
  ];

  const noCsvImportError = () => toast.error(error_message.csv.no_data);
  const uploadCsvDataToast = (message) => toast.success(message);
  const failCsvDataToast = (error) => toast.error(`${error}`);

  const validateHeaders = (headers: string[] | undefined): boolean => {
    return headers
      ? expectedHeaders.every((header) => headers.includes(header))
      : false;
  };

  const handleCsvUpload = async () => {
    setLoading(true);
    if (csvData.length === 0) {
      noCsvImportError();
      return;
    }

    const userDataArray = csvData.map((userInfo: USER) => {
      const user: USER = {
        employee_number: userInfo.employee_number,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        kana_first_name: userInfo.kana_first_name,
        kana_last_name: userInfo.kana_last_name,
        first_kana_name: userInfo.first_kana_name,
        last_kana_name: userInfo.last_kana_name,
        first_furigana_name: userInfo.first_furigana_name,
        last_furigana_name: userInfo.last_furigana_name,
        user_type: USER_TYPE.INTERNAL,
        personal_tel: userInfo.personal_tel,
        email: userInfo.email,
        is_display: Boolean(userInfo.is_display),
        login_id: userInfo.login_id,
        login_password: userInfo.login_password,
      };

      const roleData = {
        id: import.meta.env.VITE_ROLE_ID,
      };

      const userTenantData = {
        tenant_department_id: 0,
      };

      const appsArray = userInfo.apps?.split(",");

      let userApps = [];
      if (appsArray && appsArray[0] !== "") {
        userApps = appsArray.map((appName) => {
          return { name: appName };
        });
      }

      return { userInfo: user, userTenantData, roleData, userApps };
    });

    const postUserInformation = async (userDataArray) => {
      let response;
      try {
        response = await postUserDataArray(userDataArray, setLoading, setError);
        if (
          response.responseMessage.status >= 300 ||
          response.responseMessage.status == null
        ) {
          const errorMessage = TranslateErrorMessage(response.responseMessage);
          setFailedUsers(response.failed_users);
          console.error(errorMessage);
          setError(errorMessage);
          failCsvDataToast(errorMessage);
          return;
        } else {
          uploadCsvDataToast(
            messages.user.uploaded(
              response.created_users.length,
              response.failed_users.length,
            ),
          );
        }
        setFailedUsers(response.failed_users);
      } catch (error) {
        if (response && response.failed_users) {
          setFailedUsers(response.failed_users);
        }
        const errorMessage = TranslateErrorMessage(response.responseMessage);
        console.error(errorMessage);
        setError(errorMessage);
        failCsvDataToast(errorMessage);
      }
    };
    await postUserInformation(userDataArray);
  };

  const onCancel = () => {
    navigate(USER_PATHS.main);
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          <div className="bg-white bg-opacity-80 p-5 rounded-lg shadow-lg flex items-center justify-center">
            <LoadingIcon />
          </div>
        </div>
      )}
      <CsvUploader
        titleText="ユーザCSVアップロード"
        expectedHeaders={expectedHeaders}
        uploadHandler={handleCsvUpload}
        setCsvData={setCsvData}
        csvData={csvData}
        onCancel={onCancel}
        sampleData={sampleData}
        failedUsers={failedUsers}
        templateName={`ユーザ用CSVサンプル_${currentDate}`}
        headerMapping={userHeaderMapping}
        fromUserPage={true}
      />
      <ToastContainer />
    </div>
  );
};

export default UserCSVUploadPage;
