export const userHeaderMapping = {
  last_name: "姓",
  first_name: "名",
  last_furigana_name: "かな（姓）",
  first_furigana_name: "かな（名）",
  last_kana_name: "英字（姓）（任意）",
  first_kana_name: "英字（名）（任意）",
  employee_number: "社員番号（任意）",
  email: "メールアドレス（任意）",
  personal_tel: "電話番号",
  login_id: "ログインID",
  login_password: "ログインパスワード",
  is_display: "アカウント状態（有効：TRUE、無効：FALSE）",
  apps: "利用アプリ",
};

export const contactHeaderMapping = {
  first_name: "名",
  last_name: "姓",
  last_furigana_name: "かな（姓）（任意）",
  first_furigana_name: "かな（名）（任意）",
  last_kana_name: "英字（姓）（任意）",
  first_kana_name: "英字（名）（任意）",
  email: "メールアドレス（任意）",
  personal_tel: "電話番号",
  position_name: "役職名 （任意）",
  company_name: "企業名 （任意）",
  company_tel: "企業電話番号 （任意）",
  company_home_page: "企業ホームページ （任意）",
};

export const headerOrder = [
  "last_name",
  "first_name",
  "last_kana_name",
  "first_kana_name",
  "last_furigana_name",
  "first_furigana_name",
  "employee_number",
  "email",
  "personal_tel",
  "login_id",
  "login_password",
  "is_display",
  "department",
  "position_name",
  "apps",
  "company_name",
  "company_tel",
  "company_address",
  "company_home_page",
];

export const translateAndReorderHeaders = (
  headers: string[],
  headerMapping,
) => {
  const translatedHeaders = headers.map((header) => {
    return headerMapping[header] || header;
  });

  const reorderedHeaders = headerOrder
    .map((key) => headerMapping[key])
    .filter((header) => header !== undefined && header !== null);

  return reorderedHeaders;
};
